import React from "react"
import PropTypes from "prop-types"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "../styles/bannerSlider.scss"

const BannerSlider = ({ children, autoplay }) => {
  let childs = children

  if (children.length === 2) {
    childs = children.concat(children)
  }

  return (
    <div className="bannerSlider">
      <Slider
        infinite
        previousButton={false}
        nextButton={false}
        autoplay={autoplay}
      >
        {childs}
      </Slider>
    </div>
  )
}

export default BannerSlider

BannerSlider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  autoplay: PropTypes.number.isRequired,
}
