/* eslint-disable indent */
import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import clsx from "clsx"
import { GatsbyImage } from "gatsby-plugin-image"

import format from "date-fns/format"
import parseISO from "date-fns/parseISO"
import { ro } from "date-fns/locale"

import { Link, graphql } from "gatsby"

import "lazysizes"

import makeStyles from "@material-ui/core/styles/makeStyles"

import List from "@material-ui/core/List"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import BannerSlider from "../components/BannerSlider"
import DynamicBanner from "../components/banners/DynamicBanner"
import SEO from "../components/SEO"

import addToSchema from "../services/addToSchema"
import Breadcrumbs from "../components/Breadcrumbs"
import Navigation from "../components/Navigation"
import Author from "../components/news/Author"
import GeneralContact from "../components/contacts/GeneralContact"

const useStyles = makeStyles(theme => ({
  image: {
    // float: 'left',
    display: "block",
    padding: theme.spacing(2),
    maxWidth: "100%",
  },
  groupName: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  groupList: {
    listStylePosition: "outside",
    padding: theme.spacing(0, 2),
    color: theme.palette.aresBlue.main,
    listStyleType: "circle",
  },

  groupItem: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.aresBlue.main}`,

    "&:hover": {
      color: theme.palette.aresBlue.main,
    },
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const getSchema = newsItem => {
  let schema = newsItem.data.schema_json.text || ""

  if (newsItem.data.author && newsItem.data.author.document) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "MedicalWebPage",
      audience: "https://schema.org/Patient",
      lastReviewed: `${newsItem.data.date}`,
      author: {
        "@type": "Person",
        name: newsItem.data.author.document.data.name.text,
        url: `${process.env.GATSBY_BASE_URL}${localizedSlugPrefix(
          newsItem
        )}/echipa/${newsItem.data.author.document.uid}/`,
      },
    })
  }

  return schema
}

const NewsItemPage = props => {
  const {
    data: { newsItem, oldNewsItems, recentNewsItems },
    pageContext: { locale, pageSlug, breadcrumbs },
    location,
  } = props

  const classes = useStyles()

  const oldItems = oldNewsItems.edges.map(r => r.node)
  const recentItems = recentNewsItems.edges.map(r => r.node)

  return (
    <>
      <SEO
        schema={getSchema(newsItem)}
        ogp={newsItem.data.ogp_tags || ""}
        meta={{
          title: newsItem.data.meta_title,
          description: newsItem.data.meta_description,
          keywords: newsItem.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={newsItem.data.noindex}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Typography
                component="span"
                variant="subtitle1"
                className={classes.date}
              >
                {`${format(parseISO(newsItem.data.date), "PPP", {
                  locale: ro,
                })} `}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    component="h1"
                    variant="h1"
                    className={classes.date}
                  >
                    {newsItem.data.title.text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {newsItem.data.image &&
                    newsItem.data.image.gatsbyImageData && (
                      <GatsbyImage
                        className={classes.image}
                        image={newsItem.data.image.gatsbyImageData}
                        alt={newsItem.data.title.text}
                      />
                    )}
                </Grid>
              </Grid>

              <Grid item>
                {newsItem.data.author && newsItem.data.author.document && (
                  <Author teamMember={newsItem.data.author.document} />
                )}

                {newsItem.data.body1 && (
                  <Navigation content={newsItem.data.body1} />
                )}

                {newsItem.data.body1 &&
                  newsItem.data.body1.map(slice => (
                    <div key={shortid.generate()}>
                      {slice.slice_type === "repeatable_image_type" && (
                        <div className="sliceRepeatableImageType">
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        </div>
                      )}

                      {slice.slice_type === "list" && (
                        <Grid
                          container
                          className={classes.sliceListType}
                          direction="column"
                        >
                          <Grid item>
                            <Typography
                              component="div"
                              dangerouslySetInnerHTML={{
                                __html: slice.primary.content.html,
                              }}
                            />
                          </Grid>
                          <Grid item sm={10}>
                            <List>
                              {slice.items.map(item => (
                                <ListItem
                                  alignItems="flex-start"
                                  key={shortid.generate()}
                                  className={classes.listItem}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt="Item Description"
                                      src={item.list_item_image.fixed.src}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.list_item_content}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                      )}

                      {slice.slice_type === "simple" && (
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                      )}

                      {slice.slice_type === "dynamic_banner" && (
                        <div className={classes.dynamicBanner}>
                          <div className={classes.dynamicBanner}>
                            {slice.items
                              .filter(
                                item =>
                                  item.banner_type === null ||
                                  item.banner_type === "contact" ||
                                  item.banner_type === "promotion" ||
                                  item.banner_type === "consultant"
                              )
                              .map(item => (
                                <div key={shortid.generate()}>
                                  <DynamicBanner
                                    data={item}
                                    location={location}
                                    locale={locale}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {recentItems.length > 0 && (
                <div>
                  <Typography component="h4" variant="h6">
                    Articole mai noi
                  </Typography>

                  <ul className={classes.groupList}>
                    {recentItems.map(item => (
                      <li key={item.id}>
                        <Link
                          className={classes.groupItem}
                          to={`${localizedSlugPrefix(newsItem)}/${pageSlug}/${item.uid
                            }/`}
                        >
                          {item.data.title.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {oldItems.length > 0 && (
                <div>
                  <Typography component="h4" variant="h6">
                    Articole mai vechi
                  </Typography>

                  <ul className={classes.groupList}>
                    {oldItems.map(item => (
                      <li key={item.id}>
                        <Link
                          className={classes.groupItem}
                          to={`${localizedSlugPrefix(newsItem)}/${pageSlug}/${item.uid
                            }/`}
                        >
                          {item.data.title.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                <GeneralContact location={location} locale={locale} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default NewsItemPage

export const query = graphql`
  query PrismicNewsItemBySlug($slug: String!, $locale: String!, $date: Date!) {
    newsItem: prismicNews(uid: { eq: $slug }, lang: { eq: $locale }) {
      lang
      id
      data {
        date
        title {
          text
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 600)
        }
        author {
          document {
            ... on PrismicTeamMember {
              uid
              lang
              data {
                name {
                  text
                }
                competence
                specialisations {
                  specialisation: specialization {
                    document {
                      ... on PrismicSpecialisation {
                        id
                        uid
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body1 {
          ... on PrismicNewsDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicNewsDataBody1DynamicBanner {
            id
            slice_type

            items {
              banner_type
              banner_text {
                html
                text
              }
              banner_text_2 {
                html
              }
              phone
              display_phone
              consultant_image {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
      uid
    }

    oldNewsItems: allPrismicNews(
      sort: { fields: data___date, order: DESC }
      filter: {
        lang: { eq: $locale }
        data: { date: { lte: $date } }
        uid: { ne: $slug }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date
          }
          uid
        }
      }
    }

    recentNewsItems: allPrismicNews(
      sort: { fields: data___date, order: DESC }
      filter: {
        lang: { eq: $locale }
        data: { date: { gte: $date } }
        uid: { ne: $slug }
      }
      limit: 5
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date
          }
          uid
        }
      }
    }
  }
`

NewsItemPage.propTypes = {
  data: PropTypes.shape({
    newsItem: PropTypes.object.isRequired,
    oldNewsItems: PropTypes.object.isRequired,
    recentNewsItems: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
