import { graphql, useStaticQuery, Link } from "gatsby"

export default uid => {
  const data = useStaticQuery(graphql`
    query AuthorContactLocQuery {
      contactLocations: allPrismicContactLocation {
        edges {
          node {
            id
            uid
            lang
            data {
              title {
                text
              }
              long_title {
                text
              }
              gallery {
                image {
                  alt
                  gatsbyImageData(layout: FULL_WIDTH)
                  url
                }
              }
              description {
                html
              }
              city {
                text
              }
              address {
                text
              }
              coordinates {
                latitude
                longitude
              }
              team_members {
                team_member {
                  document {
                    ... on PrismicTeamMember {
                      id
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const contactLocations = data.contactLocations.edges.map(r => r.node)

  const teamMembersUidContactLocationsMap = []

  contactLocations.map(cl => {
    cl.data.team_members
      .filter(tm => tm.team_member.document)
      .map(tm => {
        if (!teamMembersUidContactLocationsMap[tm.team_member.document.uid]) {
          teamMembersUidContactLocationsMap[tm.team_member.document.uid] = []
        }

        teamMembersUidContactLocationsMap[tm.team_member.document.uid].push(cl)
      })
  })

  return teamMembersUidContactLocationsMap[uid]
}
